import postService from '../services/post';
import helpers from '../services/helpers';

const featuredWidgetController = ({ $w, appParams }) => ({
  pageReady: () =>
    postService
      .getFeaturedPost({ instance: appParams.instance })
      .then(post => {
        if (!post) {
          $w('@statebox').changeState($w('@empty'));
        } else {
          $w('@image').src = post.coverImage;
          $w('@title').text = post.title;
          $w('@date').text = helpers.getLastPublishedDate(post.lastPublishedDate);
        }
      })
      .catch(console.error),
});

export default featuredWidgetController;
