import emptyController from './controllers/empty';
import postPageController from './controllers/post-page';
import featuredWidgetController from './controllers/featured-widget';
import postListWidgetController from './controllers/post-list-widget';
import { POST_WIDGET_ID_PROD, FEATURED_WIDGET_TYPE, POST_LIST_WIDGET_TYPE } from './constants/widgets';

const controllerByType = {
  [POST_WIDGET_ID_PROD]: postPageController,
  [FEATURED_WIDGET_TYPE]: featuredWidgetController,
  [POST_LIST_WIDGET_TYPE]: postListWidgetController,
};

export const initAppForPage = () => Promise.resolve();

export const createControllers = controllerConfigs =>
  controllerConfigs.map(config => Promise.resolve((controllerByType[config.type] || emptyController)(config)));

export const exports = {};
