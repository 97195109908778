const getLastPublishedDate = lastPublishedDate =>
  new Date(lastPublishedDate).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

export default {
  getLastPublishedDate,
};
