import postService from '../services/post';
import helpers from '../services/helpers';

const postListWidgetController = ({ $w, appParams, wixCodeApi }) => ({
  pageReady: () => {
    $w('@postList').onItemReady(($item, itemData) => {
      $item('@image').src = itemData.coverImage;
      $item('@title').text = itemData.title;
      $item('@title').onClick(() => wixCodeApi.location.to(`${wixCodeApi.location.baseUrl}${itemData.postPageUrl}`));
      $item('@date').text = helpers.getLastPublishedDate(itemData.lastPublishedDate);
    });

    postService
      .getPosts({ instance: appParams.instance, params: { limit: 3 } })
      .then(posts => {
        if (!posts) {
          $w('@statebox').changeState($w('@empty'));
        } else {
          $w('@postList').data = posts;
        }
      })
      .catch(console.error);
  },
});

export default postListWidgetController;
