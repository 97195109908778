import { postMapper } from '@wix/communities-blog-wix-code-common';
import urlService from './url';

const getPostBySlug = ({ instance, slug, wixCodeApi }) =>
  fetch(`${urlService.getAPIUrl(wixCodeApi)}/posts/slugs/${slug}`, {
    headers: {
      Accept: 'application/json',
      Authorization: instance,
    },
  })
    .then(resp => (resp.ok ? resp.json() : {}))
    .then(json => json.post)
    .then(postMapper.mapRest)
    .catch(() => undefined);

const getPostById = ({ instance, id, wixCodeApi }) =>
  fetch(`${urlService.getAPIUrl(wixCodeApi)}/posts/${id}`, {
    headers: {
      Accept: 'application/json',
      Authorization: instance,
    },
  })
    .then(resp => (resp.ok ? resp.json() : {}))
    .then(json => json.post)
    .then(postMapper.mapRest)
    .catch(() => undefined);

const getQuery = obj =>
  Object.keys(obj)
    .map(key => `${key}=${obj[key]}`)
    .join('&');

const getPosts = ({ instance, params, wixCodeApi }) =>
  fetch(`${urlService.getAPIUrl(wixCodeApi)}/posts?${getQuery(params)}`, {
    headers: {
      Accept: 'application/json',
      Authorization: instance,
    },
  })
    .then(resp => (resp.ok ? resp.json() : {}))
    .then(({ post }) => {
      const posts = post ? post.map(postMapper.mapRest) : [{}];
      return params.limit && params.limit === 1 ? posts[0] : posts;
    })
    .catch(() => undefined);

const getLastPost = ({ instance, wixCodeApi }) => getPosts({ instance, params: { limit: 1 }, wixCodeApi });
const getFeaturedPost = ({ instance, wixCodeApi }) =>
  getPosts({ instance, params: { limit: 1, featured: true }, wixCodeApi });

export default {
  getPostById,
  getPostBySlug,
  getLastPost,
  getFeaturedPost,
  getPosts,
};
